var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (e, i) {
  exports = i();
}(exports, function () {
  "use strict";

  return function (e, i) {
    i.prototype.isSameOrBefore = function (e, i) {
      return this.isSame(e, i) || this.isBefore(e, i);
    };
  };
});
export default exports;